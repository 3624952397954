<template>
  <v-container grid-list-md>
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Generar nueva Clave AES
        </v-card-title>
        <container>
          <v-card-text>
            Pulsado aceptar, la aplicación propondrá una nueva clave AES. Una vez generada la nueva
            clave, deberá pulsar guardar para almacenar la nueva clave. Recuerde sustituir la
            antigua clave por la nueva en sus desarrollos.
          </v-card-text>
        </container>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="updateAesComplete">
            Aceptar
          </v-btn>
          <v-btn color="primary" text @click="dialog = false"> Cancelar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-layout row wrap>
      <v-flex xs12 md6 my-auto>
        <h1>{{ operator.name }}</h1>
      </v-flex>

      <v-flex xs6 md3 class="text-center d-none d-md-block"> </v-flex>

      <v-flex xs12 md3 class="text-center d-none d-md-block">
        <h3>Nº Integradores</h3>
        <v-chip>{{ operator.integrators.length }}</v-chip>
      </v-flex>
    </v-layout>

    <v-layout row wrap>
      <v-flex xs12 md6>
        <v-card class="ma-2" elevation="1">
          <v-card-text class="pt-5 pb-1 py-5"><b>Configuración</b></v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="operator.aesParaph"
                  append-icon="mdi-content-copy"
                  append-outer-icon="mdi-refresh-circle"
                  prepend-icon="mdi-key"
                  filled
                  :rules="rulesAes"
                  required
                  hide-details="auto"
                  label="Clave AES"
                  :type="aesInputType"
                  @click:append-outer="updateAes"
                >
                  <v-icon slot="append" @click="changeAesInput" title="Ver clave"> mdi-eye</v-icon>
                  <v-icon
                    slot="append"
                    class="ml-2"
                    @click="copyAes"
                    title="Copiar al portapapeles"
                  >
                    mdi-content-copy
                  </v-icon>
                </v-text-field>
              </v-col>
            </v-row>

            <label class="ml-8">
              Prefijo: <v-chip class="mr-6">{{ operator.prefixTitul }}</v-chip>
            </label>
            <label>
              Nº de clave: <v-chip>{{ operator.secKey }}</v-chip>
            </label>

            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Código del comercio"
                  v-model="operator.merchantId"
                  filled
                  clearable
                  hide-details="auto"
                  prepend-icon="mdi-shopping"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="operator.paymode"
                  :items="paymodes"
                  filled
                  hide-details="auto"
                  label="Modo de pago"
                  prepend-icon="mdi-cash-multiple"
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="URL Imagen Operador"
                  v-model="operator.image"
                  filled
                  clearable
                  hide-details="auto"
                  prepend-icon="mdi-image-filter-black-white"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="text-center">
                <v-btn color="accent" :loading="loading" @click="saveOperator" elevation="3" block
                  >GUARDAR</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-flex>

      <v-flex xs12 md6>
        <MediaAccess></MediaAccess>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import MediaAccess from "../components/MediaAccess.vue";

export default {
  components: { MediaAccess },
  name: "Operator",
  data: () => ({
    paymodes: ["EMV", "NORMA19"],
    dialog: false,
    aesInputType: "password",
    rulesAes: [
      value => !!value || "Clave AES obligatoria.",
      value => (value && value.length === 16) || "Máximo 16 caracteres.",
    ],
  }),
  computed: {
    loading() {
      return this.$store.getters.getLoading;
    },
    operator() {
      return this.$store.getters.getOperator;
    },
  },
  methods: {
    changeAesInput() {
      if (this.aesInputType == "text") this.aesInputType = "password";
      else this.aesInputType = "text";
    },
    copyAes() {
      var aux = document.createElement("input");
      aux.setAttribute("value", this.operator.aesParaph);
      document.body.appendChild(aux);
      aux.select();
      document.execCommand("copy");
      document.body.removeChild(aux);
      this.$store.commit("showBanner", {
        bannerMessage: "Clave AES copiada al portapapeles.",
        bannerType: "success",
      });
    },
    updateAes() {
      this.dialog = true;
    },
    updateAesComplete() {
      this.dialog = false;
      this.$store.dispatch("generateAES").then(response => {
        this.operator.aesParaph = response;
      });
    },
    saveOperator() {
      this.operator.aesParaph = this.operator.aesParaph.toUpperCase();
      this.$store.dispatch("saveOperator");
    },
  },
};
</script>

<style scoped></style>
