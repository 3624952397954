<template>
  <v-container>
    <v-card elevation="1">
      <v-simple-table dense>
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Medios de acceso</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <div class="flex-grow-1"></div>
            <v-btn class="mr-2" color="primary" elevation="2" x-small @click="add">
              <v-icon small>mdi-plus</v-icon>Añadir
            </v-btn>
          </v-toolbar>
        </template>

        <template>
          <thead>
            <tr>
              <th>Medio Acceso</th>
              <th>Nombre</th>
              <th>Fecha Fin</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in mediaAccess" :key="item.mediaAccessId">
              <td>{{ item.mediaAccessId }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.end }}</td>
              <td class="text-right">
                <v-btn outlined color="primary" elevation="2" x-small @click="edit(item)">
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>

    <v-dialog v-model="editorDialog" max-width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Medio de acceso
        </v-card-title>
        <v-form ref="form" lazy-validation @keyup.native.enter="submit">
          <v-container>
            <v-card-text>
              <v-container class="pb-0" fluid>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="Código"
                      v-model="mediaAccessItem.mediaAccessId"
                      filled
                      maxlength="3"
                      :readonly="editMode"
                      hide-details="auto"
                      :rules="requiredRule"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      label="Nombre"
                      v-model="mediaAccessItem.name"
                      filled
                      clearable
                      hide-details="auto"
                      :rules="requiredRule"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-menu
                      v-model="menu"
                      :close-on-content-click="true"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="mediaAccessItem.end"
                          label="Fecha fin"
                          filled
                          clearable
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          hide-details="auto"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        v-model="dateEnd"
                        no-title
                        @input="menu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-container>
        </v-form>

        <v-progress-linear :indeterminate="loading" color="primary" class="mb-0">
        </v-progress-linear>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="save"> Guardar </v-btn>
          <v-btn color="primary" text @click="editorDialog = false">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
const validation = require("../shared/validation");

export default {
  name: "MediaAccess",
  data: () => ({
    requiredRule: [v => (v != null && v != "") || "Campo obligatorio"],
    editorDialog: false,
    editMode: false,
    menu: false,
    mediaAccessItem: {},
    dateEnd: null,
  }),
  computed: {
    loading() {
      return this.$store.getters.getLoading;
    },
    mediaAccess() {
      return this.$store.getters.getMediaAccess;
    },
  },
  watch: {
    dateEnd() {
      this.mediaAccessItem.end = validation.formatDate(this.dateEnd);
    },
  },
  methods: {
    add: function() {
      this.mediaAccessItem = {};
      this.editorDialog = true;
      this.editMode = false;
      this.date = null;
    },
    edit: function(item) {
      this.mediaAccessItem = item;
      this.editorDialog = true;
      this.editMode = true;
      if (item.end != null) {
        this.dateEnd = validation.parseDate(item.end);
      }
    },
    save: function() {
      var form = this.$refs.form;
      if (form.validate()) {
        this.$store.dispatch("saveMediaAccess", this.mediaAccessItem).then(
          response => {
            if (!this.editMode) this.$store.commit("appendMediaAccess", response);
            this.editorDialog = false;
          },
          () => {
            this.editorDialog = true;
          }
        );
      }
    },
  },
};
</script>

<style scoped></style>
